<script lang="ts">
    import { Skeleton } from '$lib/components/ui/skeleton';
    export let data;
</script>

<div class="flex items-center space-x-4 border-b px-4 py-3">
    <Skeleton class="h-12 w-12 rounded-full {data.lastContactID ? '' : 'animate-none'}" />
    <div class="w-[90%] space-y-2">
        <Skeleton class="h-4 w-[100%] {data.lastContactID ? '' : 'animate-none'}" />
        <Skeleton class="h-4 w-[100%] {data.lastContactID ? '' : 'animate-none'}" />
    </div>
</div>
